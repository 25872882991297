// let ipAddr ="localhost";  
let ipAddr = "api.imlasoft.ma";

const config = {

  apiUrl: "https://" + ipAddr + "/api/",

};

export default config;
