import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";
import { Box, useTheme, Button, useMediaQuery, TextField } from "@mui/material";
import Header from "../components/Header";
import { tokens } from "../theme";
import {
  DataGrid,
  GridActionsCellItem,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import config from "./global/config";
import { ReactSession } from "react-client-session";
import * as Yup from "yup";
import TextfieldWrapper from "../components/FormsUI/Textfield";
import DateTimePicker from "../components/FormsUI/DataTimePicker";
import ButtonWrapper from "../components/FormsUI/Button";
import { Form, Formik } from "formik";

function Mission() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const url = config.apiUrl + "mission.php";
  const urlTrajectory = config.apiUrl + "trajectory.php";
  const urlClient = config.apiUrl + "selection.php";
  const urlProduit = config.apiUrl + "selection.php";

  const urlTypeOperation = config.apiUrl + "type_operation.php";
  const urlUnite = config.apiUrl + "unite.php";
  const urlStatut = config.apiUrl + "statut.php";
  const urlProfile = config.apiUrl + "profile.php";

  const [data, setData] = useState([]);
  const [dataTrajectory, setDataTrajectory] = useState([]);
  const [dataClient, setDataClient] = useState([]);
  const [dataProduit, setDataProduit] = useState([]);
  const [dataProfile, setDataProfile] = useState([]);
  const [dataTypeOperation, setDataTypeOperation] = useState([]);
  const [dataUnite, setDataUnite] = useState([]);
  const [dataStatut, setDataStatut] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [MissionSelected, setMissionSelected] = useState({
    idMission: "",
    label_mission: "",
    idC: "",
    nom_c: "",
    prenom_c: "",
    idP: "",
    designation: "",
    idTraj: "",
    date_start: "",
    date_end: "",
    type_operation: "",
    quantite: "",
    unite: "",
    statut: "",
    planifie: "",
    effectue: "",
    reste: "",
    label: "",
  });

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setMissionSelected((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(MissionSelected);
  };

  const clearSelected = () => {
    setMissionSelected({
      idMission: "",
      label_mission: "",
      idC: "",
      nom_c: "",
      prenom_c: "",
      idP: "",
      designation: "",
      idTraj: "",
      date_start: "",
      date_end: "",
      type_operation: "",
      quantite: "",
      unite: "",
      statut: "",
      planifie: "",
      effectue: "",
      reste: "",
      label: "",
    });
  };
  const FORM_VALIDATION = Yup.object().shape({
    label_mission: Yup.string().required("Required"),
    idC: Yup.string().required("Required"),
    idP: Yup.string().required("Required"),
    idTraj: Yup.string().required("Required"),
    date_start: Yup.date().required("Required"),
    date_end: Yup.date().required("Required"),
    type_operation: Yup.string().required("Required"),
    quantite: Yup.number().required("Required"),
    unite: Yup.string().required("Required"),
    statut: Yup.string().required("Required"),
  });
  const openCloseModalInsert = () => {
    clearSelected();
    setModalAdd(!modalAdd);
  };

  const openCloseModalEdit = () => {
    setModalEdit(!modalEdit);
  };

  const openCloseModalDelete = () => {
    setModalDelete(!modalDelete);
  };

  const requestGet = async () => {
    await axios
      .get(url)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const requestGetProfile = async (id, page) => {
    await axios
      .get(urlProfile, {
        params: { idUser: id, page: page },
      })
      .then((response) => {
        setDataProfile(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const requestGetTrajectory = async () => {
    await axios
      .get(urlTrajectory)
      .then((response) => {
        setDataTrajectory(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const requestGetClient = async () => {
    await axios
      .get(urlClient, { params: { client: "" } })
      .then((response) => {
        setDataClient(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const requestGetProduit = async () => {
    await axios
      .get(urlProduit, { params: { produit: "" } })
      .then((response) => {
        setDataProduit(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const requestGetTypeOperation = async () => {
    await axios
      .get(urlTypeOperation)
      .then((response) => {
        setDataTypeOperation(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const requestGetUnite = async () => {
    await axios
      .get(urlUnite)
      .then((response) => {
        setDataUnite(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const requestGetStatut = async () => {
    await axios
      .get(urlStatut)
      .then((response) => {
        setDataStatut(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const requestPost = async (MissionSelected) => {
    const f = new FormData();
    f.append("label_mission", MissionSelected.label_mission);
    f.append("idC", MissionSelected.idC);
    f.append("idP", MissionSelected.idP);
    f.append("idTraj", MissionSelected.idTraj);
    f.append("date_start", MissionSelected.date_start);
    f.append("date_end", MissionSelected.date_end);
    f.append("type_operation", MissionSelected.type_operation);
    f.append("quantite", MissionSelected.quantite);
    f.append("unite", MissionSelected.unite);
    f.append("statut", MissionSelected.statut);
    f.append("METHOD", "POST");

    try {
      const response = await axios.post(url, f);
      setData((prevData) => [...prevData, response.data]);
      requestGet();
      openCloseModalInsert();
      clearSelected();
    } catch (error) {
      console.error(error);
    }
  };

  const requestPut = async () => {
    const f = new FormData();
    f.append("idMission", MissionSelected.idMission);
    f.append("label_mission", MissionSelected.label_mission);
    f.append("idC", MissionSelected.idC);
    f.append("idP", MissionSelected.idP);
    f.append("idTraj", MissionSelected.idTraj);
    f.append("date_start", MissionSelected.date_start);
    f.append("date_end", MissionSelected.date_end);
    f.append("type_operation", MissionSelected.type_operation);
    f.append("quantite", MissionSelected.quantite);
    f.append("unite", MissionSelected.unite);
    f.append("statut", MissionSelected.statut);
    f.append("METHOD", "PUT");

    try {
      await axios.post(url, f, {
        params: { idMission: MissionSelected.idMission },
      });

      setData((prevData) => {
        return prevData.map((mission) =>
          mission.idMission === MissionSelected.idMission
            ? { ...mission, ...MissionSelected }
            : mission
        );
      });

      requestGet();
      openCloseModalEdit();
      clearSelected();
    } catch (error) {
      console.error(error);
    }
  };

  const requestDelete = async () => {
    var f = new FormData();
    f.append("METHOD", "DELETE");
    await axios
      .post(url, f, { params: { idMission: MissionSelected.idMission } })
      .then((response) => {
        setData(
          data.filter(
            (mission) => mission.idMission !== MissionSelected.idMission
          )
        );
        openCloseModalDelete();
        clearSelected();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectCommand = (mission, choix) => {
    setMissionSelected(mission);
    choix === "Edit" ? openCloseModalEdit() : openCloseModalDelete();
  };
  
  const columns = [
    {
      field: "idMission",
      headerName: "ID",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "label_mission",
      headerName: "Label",
      flex: 1.5,
    },
    {
      field: "nom",
      headerName: "Client",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "designation",
      headerName: "Produit",
      flex: 1,
    },
    {
      field: "label",
      headerName: "Trajectoire",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "date_start",
      headerName: "Date Début",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "date_end",
      headerName: "Date Fin",
      headerAlign: "left",
      align: "left",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "type_operation",
      headerName: "Type d'Operation",
      flex: 1,
    },
    {
      field: "quantite",
      headerName: "Quantité",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "unite",
      headerName: "Unité",
      flex: 1,
    },
    {
      field: "statut",
      headerName: "Statut",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "actions",
      label: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderCell: (params) => {
        return [
          (dataProfile.length > 0 && dataProfile[0].op_edit == 1) ||
          ReactSession.get("role") == "admin" ? (
            <GridActionsCellItem
              key={`edit-${params.row.idMission}`}
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => selectCommand(params.row, "Edit")}
              color="inherit"
            />
          ) : null,
          (dataProfile.length > 0 && dataProfile[0].op_delete == 1) ||
          ReactSession.get("role") == "admin" ? (
            <GridActionsCellItem
              key={`delete-${params.row.idMission}`}
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => selectCommand(params.row, "Delete")}
              color="inherit"
            />
          ) : null,
        ];
      },
    },
  ];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        requestGetProduit();
        requestGetTrajectory();
        requestGetClient();

        requestGetTypeOperation();
        requestGetUnite();
        requestGetStatut();
        // console.log(ReactSession.get('idUser'));
        requestGetProfile(ReactSession.get("idUser"), "Mission");
        requestGet();
        // console.log(dataProfile[0]);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    if (!loading) {
      window.location.reload();
    }
  }, []);

  return (
    <Box m="25px">
      {loading ? (
        <Header title="Loading" subtitle="" />
      ) : (
        <Header title="Mission" subtitle="Liste des Missions" />
      )}
      {loading ? null : (dataProfile.length > 0 &&
          dataProfile[0].op_add == 1) ||
        ReactSession.get("role") == "admin" ? (
        <Box display="flex" justifyContent="end" mt="20px">
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            onClick={() => openCloseModalInsert()}
          >
            Nouvelle Mission
          </Button>
        </Box>
      ) : null}
      <Modal isOpen={modalAdd}>
        <ModalHeader
          toggle={() => {
            openCloseModalInsert();
          }}
          style={
            theme.palette.mode === "dark"
              ? { backgroundColor: colors.primary[500] }
              : { backgroundColor: "#fcfcfc" }
          }
        >
          Ajouter une Mission{" "}
        </ModalHeader>
        <Formik
          initialValues={{
            ...MissionSelected,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(MissionSelected) => {
            requestPost(MissionSelected);
          }}
        >
          <Form>
            <ModalBody
              style={
                theme.palette.mode === "dark"
                  ? { backgroundColor: colors.primary[500] }
                  : { backgroundColor: "#fcfcfc" }
              }
            >
              <Box
                display="grid"
                gap="20px"
                gridTemplateColumns="repeat(8, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextfieldWrapper
                  fullWidth
                  type="text"
                  label="Label"
                  // onBlur={handleBlur}
                  name="label_mission"
                  // error={!!touched.firstName && !!errors.firstName}
                  // helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: "span 8" }}
                />

                <TextfieldWrapper
                  fullWidth
                  // id="outlined-select-currency"
                  select
                  label="Client"
                  name="idC"
                  SelectProps={{
                    native: true,
                  }}
                  // defaultValue={MissionSelected && MissionSelected.idTraj}
                  sx={{ gridColumn: "span 8" }}
                >
                  <option value="" selected>
                    Choisissez un Client
                  </option>
                  {dataClient.map((user) => (
                    <option value={user.idC} key={user.idC}>
                      {user.nom_c + " " + user.prenom_c}
                    </option>
                  ))}
                </TextfieldWrapper>
                <TextfieldWrapper
                  fullWidth
                  // id="outlined-select-currency"
                  select
                  label="Produit"
                  name="idP"
                  SelectProps={{
                    native: true,
                  }}
                  // defaultValue={MissionSelected && MissionSelected.idTraj}
                  sx={{ gridColumn: "span 8" }}
                >
                  <option value="" selected>
                    Choisissez un Produit
                  </option>
                  {dataProduit.map((user) => (
                    <option value={user.idP} key={user.idP}>
                      {user.designation}
                    </option>
                  ))}
                </TextfieldWrapper>
                <TextfieldWrapper
                  fullWidth
                  // id="outlined-select-currency"
                  select
                  label="Trajectoire"
                  name="idTraj"
                  SelectProps={{
                    native: true,
                  }}
                  // defaultValue={MissionSelected && MissionSelected.idTraj}
                  sx={{ gridColumn: "span 8" }}
                >
                  <option value="" selected>
                    Choisissez un Trajet
                  </option>
                  {dataTrajectory.map((user) => (
                    <option value={user.idTraj} key={user.idTraj}>
                      {user.label}
                    </option>
                  ))}
                </TextfieldWrapper>

                <DateTimePicker
                  fullWidth
                  label="Date Début"
                  // onBlur={handleBlur}

                  name="date_start"
                  // error={!!touched.firstName && !!errors.firstName}
                  // helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: "span 4" }}
                />

                <DateTimePicker
                  fullWidth
                  type="date"
                  label="Date Fin"
                  // onBlur={handleBlur}

                  name="date_end"
                  // error={!!touched.firstName && !!errors.firstName}
                  // helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: "span 4" }}
                />

                <TextfieldWrapper
                  fullWidth
                  // id="outlined-select-currency"
                  select
                  label="Type d'Operation"
                  name="type_operation"
                  SelectProps={{
                    native: true,
                  }}
                  // defaultValue={MissionSelected && MissionSelected.type_operation}
                  sx={{ gridColumn: "span 4" }}
                >
                  <option value="" selected>
                    Choisissez un Type
                  </option>
                  {dataTypeOperation.map((item) => (
                    <option value={item.type} key={item.type}>
                      {item.type}
                    </option>
                  ))}
                </TextfieldWrapper>

                <TextfieldWrapper
                  fullWidth
                  type="number"
                  label="Quantité"
                  // onBlur={handleBlur}

                  name="quantite"
                  // error={!!touched.firstName && !!errors.firstName}
                  // helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: "span 4" }}
                />

                <TextfieldWrapper
                  fullWidth
                  // id="outlined-select-currency"
                  select
                  label="Unité"
                  name="unite"
                  SelectProps={{
                    native: true,
                  }}
                  // defaultValue={MissionSelected && MissionSelected.unite}
                  sx={{ gridColumn: "span 4" }}
                >
                  <option value="" selected>
                    Choisissez une Unité
                  </option>
                  {dataUnite.map((item) => (
                    <option value={item.unite} key={item.unite}>
                      {item.unite}
                    </option>
                  ))}
                </TextfieldWrapper>

                <TextfieldWrapper
                  fullWidth
                  // id="outlined-select-currency"
                  select
                  label="Statut"
                  name="statut"
                  SelectProps={{
                    native: true,
                  }}
                  // defaultValue={MissionSelected && MissionSelected.statut}
                  sx={{ gridColumn: "span 4" }}
                >
                  <option value="" selected>
                    Choisissez un Statut
                  </option>
                  {dataStatut.map((item) => (
                    <option value={item.statut} key={item.statut}>
                      {item.statut}
                    </option>
                  ))}
                </TextfieldWrapper>
              </Box>
            </ModalBody>
            <ModalFooter
              style={
                theme.palette.mode === "dark"
                  ? { backgroundColor: colors.primary[500] }
                  : { backgroundColor: "#fcfcfc" }
              }
            >
              <ButtonWrapper className="btn btn-primary">Ajouter</ButtonWrapper>{" "}
              <button
                className=" btn btn-danger"
                onClick={() => openCloseModalInsert()}
              >
                Fermer
              </button>
            </ModalFooter>
          </Form>
        </Formik>
      </Modal>

      <Modal isOpen={modalEdit}>
        <ModalHeader
          style={
            theme.palette.mode === "dark"
              ? { backgroundColor: colors.primary[500] }
              : { backgroundColor: "#fcfcfc" }
          }
        >
          Modifier une Mission{" "}
        </ModalHeader>
        <ModalBody
          style={
            theme.palette.mode === "dark"
              ? { backgroundColor: colors.primary[500] }
              : { backgroundColor: "#fcfcfc" }
          }
        >
          <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(8, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Label"
              onChange={HandleChange}
              name="label_mission"
              defaultValue={MissionSelected && MissionSelected.label_mission}
              sx={{ gridColumn: "span 8" }}
            />

            <TextField
              fullWidth
              variant="filled"
              id="outlined-select-currency"
              select
              label="Client"
              onChange={HandleChange}
              name="idC"
              SelectProps={{
                native: true,
              }}
              defaultValue={MissionSelected && MissionSelected.idC}
              sx={{ gridColumn: "span 8" }}
            >
              {dataClient.map((user) => (
                <option value={user.idC} key={user.idC}>
                  {user.nom_c + " " + user.prenom_c}
                </option>
              ))}
            </TextField>

            <TextField
              fullWidth
              variant="filled"
              id="outlined-select-currency"
              select
              label="Produit"
              onChange={HandleChange}
              name="idP"
              SelectProps={{
                native: true,
              }}
              defaultValue={MissionSelected && MissionSelected.idP}
              sx={{ gridColumn: "span 8" }}
            >
              {dataProduit.map((user) => (
                <option value={user.idP} key={user.idP}>
                  {user.designation}
                </option>
              ))}
            </TextField>
            <TextField
              fullWidth
              variant="filled"
              id="outlined-select-currency"
              select
              label="Trajectoire"
              onChange={HandleChange}
              name="idTraj"
              SelectProps={{
                native: true,
              }}
              defaultValue={MissionSelected && MissionSelected.idTraj}
              sx={{ gridColumn: "span 8" }}
            >
              {dataTrajectory.map((user) => (
                <option value={user.idTraj} key={user.idTraj}>
                  {user.label}
                </option>
              ))}
            </TextField>

            <TextField
              fullWidth
              variant="filled"
              type="date"
              label="Date Début"
              onChange={HandleChange}
              name="date_start"
              defaultValue={MissionSelected && MissionSelected.date_start}
              sx={{ gridColumn: "span 4" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="date"
              label="Date Fin"
              onChange={HandleChange}
              name="date_end"
              defaultValue={MissionSelected && MissionSelected.date_end}
              sx={{ gridColumn: "span 4" }}
            />

            <TextField
              fullWidth
              variant="filled"
              // id="outlined-select-currency"
              select
              label="Type d'Operation"
              onChange={HandleChange}
              name="type_operation"
              SelectProps={{
                native: true,
              }}
              defaultValue={MissionSelected && MissionSelected.type_operation}
              sx={{ gridColumn: "span 4" }}
            >
              {dataTypeOperation.map((item) => (
                <option value={item.type} key={item.type}>
                  {item.type}
                </option>
              ))}
            </TextField>

            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Quantité"
              onChange={HandleChange}
              name="quantite"
              defaultValue={MissionSelected && MissionSelected.quantite}
              sx={{ gridColumn: "span 4" }}
            />

            <TextField
              fullWidth
              variant="filled"
              // id="outlined-select-currency"
              select
              label="Unité"
              onChange={HandleChange}
              name="unite"
              SelectProps={{
                native: true,
              }}
              defaultValue={MissionSelected && MissionSelected.unite}
              sx={{ gridColumn: "span 4" }}
            >
              {dataUnite.map((item) => (
                <option value={item.unite} key={item.unite}>
                  {item.unite}
                </option>
              ))}
            </TextField>

            <TextField
              fullWidth
              variant="filled"
              // id="outlined-select-currency"
              select
              label="Statut"
              onChange={HandleChange}
              name="statut"
              SelectProps={{
                native: true,
              }}
              defaultValue={MissionSelected && MissionSelected.statut}
              sx={{ gridColumn: "span 4" }}
            >
              {dataStatut.map((item) => (
                <option value={item.statut} key={item.statut}>
                  {item.statut}
                </option>
              ))}
            </TextField>
          </Box>
        </ModalBody>
        <ModalFooter
          style={
            theme.palette.mode === "dark"
              ? { backgroundColor: colors.primary[500] }
              : { backgroundColor: "#fcfcfc" }
          }
        >
          <button className="btn btn-primary" onClick={() => requestPut()}>
            Modifier
          </button>{" "}
          <button
            className=" btn btn-danger"
            onClick={() => openCloseModalEdit()}
          >
            Fermer
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalDelete}
        style={
          theme.palette.mode === "dark"
            ? { backgroundColor: colors.primary[500] }
            : { backgroundColor: "#fcfcfc" }
        }
      >
        <ModalBody
          style={
            theme.palette.mode === "dark"
              ? { backgroundColor: colors.primary[500] }
              : { backgroundColor: "#fcfcfc" }
          }
        >
          Etes-vous sûr que vous voulez supprimer Mission N°{" "}
          {MissionSelected && MissionSelected.idMission} ?
        </ModalBody>
        <ModalFooter
          style={
            theme.palette.mode === "dark"
              ? { backgroundColor: colors.primary[500] }
              : { backgroundColor: "#fcfcfc" }
          }
        >
          <button className="btn btn-danger" onClick={() => requestDelete()}>
            Oui
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => openCloseModalDelete()}
          >
            {" "}
            Non
          </button>
        </ModalFooter>
      </Modal>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .name-column--cell2": {
            color: colors.redAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data.map((mission) => {
            return {
              id: mission.idMission,
              idMission: mission.idMission,
              label_mission: mission.label_mission,
              idC: mission.idC,
              nom: mission.prenom_c + " " + mission.nom_c,
              idP: mission.idP,
              designation: mission.designation,
              label: mission.label,
              idTraj: mission.idTraj,
              date_start: mission.date_start,
              date_end: mission.date_end,
              type_operation: mission.type_operation,
              quantite: mission.quantite,
              unite: mission.unite,
              statut: mission.statut,
            };
          })}
          getRowId={(row) => row.id}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Box>
    </Box>
  );
}

const csvOptions = {
  fileName: "BD_Missions",
  delimiter: ";",
  utf8WithBom: true,
};
const printOptions = {
  hideFooter: true,
  hideToolbar: true,
  includeCheckboxes: true,
};

function CustomExportButtons(props) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  );
}

function CustomToolbar(props) {
  return (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      {/* <GridToolbarFilterButton /> */}
      <GridToolbarDensitySelector />
      <CustomExportButtons />
      <Box
        sx={{
          marginLeft: "auto",
          pl: 0,
          pr: 0,
          pb: 0,
          pt: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  );
}

export default Mission;
